

$(document).ready(function () { 
  function calcWidth() {    
		var navwidth = 0;
		var morewidth = $('#main .more').outerWidth(true);
		$('#main > li:not(.more)').each(function() {
			navwidth += $(this).outerWidth( true );
		});

		var availablespace = $('#nav-main').width() - morewidth;

		if (navwidth > availablespace) {
			var lastItem = $('#main > li:not(.more)').last();
			lastItem.attr('data-width', lastItem.outerWidth(true));
			lastItem.prependTo($('#main .more ul'));
			lastItem.removeClass('change');
			lastItem.addClass('insideMore');
			calcWidth();
		} else {

		var firstMoreElement = $('#main li.more li').first();
		if (navwidth + firstMoreElement.data('width') < availablespace) {
			firstMoreElement.insertBefore($('#main .more'));
		}
	}

	if ($('.more li').length > 0) {
		$('.more').css('display','block');
		} else {
			$('.more').css('display','none');
		}
	}

  $(window).on('resize load', function () {    
		var arrowWidth = $('.user-area').width() + 4;
		$('.user-info .arrow-up').css('margin-right', arrowWidth);
		$('.drop-submenu').css('display', 'none');
		calcWidth();
  }); 

  calcWidth();

  $.fn.swapWith = function(to) {
    return this.each(function() {
        var copy_to = $(to).clone(true);
        var copy_from = $(this).clone(true);
        $(to).replaceWith(copy_from);
        $(this).replaceWith(copy_to);
    });
  };

  $('body').on('click','.insideMore', function(){
		var chengableElem = $('#main li:nth-last-child(2)').first();
		chengableElem.attr('data-width', chengableElem.outerWidth(true));
		chengableElem.removeClass('change');
		chengableElem.addClass('insideMore');
		$(this).removeClass('insideMore');
		$(this).addClass('change');
    $(this).swapWith(chengableElem);
		calcWidth();
  });

	$('body').on('click','.change', function(){
		var submenuElem = $('> .drop-submenu', this);
		if (submenuElem.css('display') == 'none'){
			$('.drop-submenu').each(function(){
				$(this).css('display', 'none');
			})
			 var leftPos = $(this)[0].getBoundingClientRect().left + $(window)['scrollLeft']();
			 var width = $(this).width();
			 var marginLeft = Math.trunc(leftPos) + Math.trunc(width / 2) - 12;
			 $('.arrow-up').css('margin-left', marginLeft);
			 submenuElem.css('display', 'block');
		} else {
			submenuElem.css('display', 'none');
			$('.drop-submenu').each(function(){
				$(this).css('display', 'none');
			})
		}
		});

		$('body').on('click','.mobile-hamburger-icon', function(){
			$('.mobile-menu-content').css('display','block');
		});

		$('body').on('click','.close-menu-link', function(){
			$('.mobile-menu-content').css('display','none');
		});

  $('body').on('click', function (e) {
    if ($(e.target).hasClass('media-object') || $(e.target).hasClass('no-prof-img-area')) {
      if ($('.user-info').css('display') == 'none') {
        var arrowWidth = $('.user-area').width() + 4;
        $('.user-info .arrow-up').css('margin-right', arrowWidth);
        $('.user-info').css('display', 'block');
      } else {
        $('.user-info').css('display', 'none');
        $('.user-info .arrow-up').css('margin-right', 0);
      }
    } else {
      $('.user-info').css('display', 'none');
    }
	
	});

	var acc = document.getElementsByClassName("accordion");
	var i;

	for (i = 0; i < acc.length; i++) {
	  acc[i].addEventListener("click", function() {
	    this.classList.toggle("active");
	    var panel = this.nextElementSibling;
	    if (panel.style.maxHeight){
	      panel.style.maxHeight = null;
	    } else {
	      panel.style.maxHeight = panel.scrollHeight + "px";
	    }
	  });
  }


 
});




$(window).scroll(function () {
  if ($(document).scrollTop() > 50) {
      $('.user-info').css('display', 'none');     
      $('header').addClass('small');
		  $('.drop-submenu').addClass('small');
    } else {
        $('header').removeClass('small');
				$('.drop-submenu').removeClass('small');
    }
});
